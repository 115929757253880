<template>
  <div>
    <div class="table-header">
      <h3 class="table-title">人工巡查线路</h3>
      <div class="table-btns">
        <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新增</a-button>
        <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
      </div>
    </div>
    <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
      <span slot="patroldailytemplatename" slot-scope="value,record">
        <a-button type="link" @click="rowClick(record)">{{value}}</a-button>
      </span>
      <span slot="starttime" slot-scope="value">
         <a-tooltip :title="value ? moment(value, 'HH:mm:ss').format('HH:mm:ss') : ''">
           <span>{{value ? moment(value, 'HH:mm:ss').format('HH:mm:ss') : ''}}</span>
         </a-tooltip>
      </span>
      <span slot="endtime" slot-scope="value">
         <a-tooltip :title="value ? moment(value, 'HH:mm:ss').format('HH:mm:ss') : ''">
           <span>{{value ? moment(value, 'HH:mm:ss').format('HH:mm:ss') : ''}}</span>
         </a-tooltip>
      </span>
      <span slot="createtime" slot-scope="value">
         <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
           <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
         </a-tooltip>
      </span>
      <span slot="operation" slot-scope="value, record">
         <a-dropdown>
         <a class="ant-dropdown-link" @click="e => e.preventDefault()">
           <a-icon type="menu"/>
         </a>
         <a-menu slot="overlay" @click="operationClick">
           <a-menu-item :key="'detail-'+record.patroldailytemplateid">详情</a-menu-item>
<!--           <a-menu-item :key="'edit-'+record.patroldailytemplateid">修改</a-menu-item>-->
           <a-menu-item :key="'delete-'+record.patroldailytemplateid">作废</a-menu-item>
         </a-menu>
         </a-dropdown>
      </span>
    </a-table>
    <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
      <template slot="buildOptionText" slot-scope="props">
        <span>{{ props.value }}条/页</span>
      </template>
    </a-pagination>
    <a-modal v-model="templateVisible" :title="title" width="860px">
      <template slot="footer">
        <a-button @click="templateVisible=false">取消</a-button>
        <a-button v-if="templateType==='add'||templateType==='edit'" type="primary" @click="handleOk">提交</a-button>
      </template>
      <a-form-model ref="advancedForm" :model="queryParams" :rules="formRules" layout="inline" class="query-form" :label-col="{span:10}" :wrapper-col="{span:14}">
        <a-form-model-item label="人工巡查线路名称" prop="patroldailytemplatename">
          <a-input :disabled="templateType==='detail'" v-model="queryParams.patroldailytemplatename" style="width: 460px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="巡查最早开始时刻" prop="starttime">
          <a-time-picker :disabled="templateType==='detail'" v-model="queryParams.starttime" format="HH:mm" placeholder="" style="width: 460px"></a-time-picker>
        </a-form-model-item>
        <a-form-model-item label="巡查最晚结束时刻" prop="endtime">
          <a-time-picker :disabled="templateType==='detail'" v-model="queryParams.endtime" format="HH:mm" placeholder="" style="width: 460px"></a-time-picker>
        </a-form-model-item>
        <a-form-model-item label="人工巡查线路配置" prop="patrolline">
<!--          <a-select :disabled="templateType==='detail'" mode="multiple" v-model="patrolline" :token-separators="[',']"  @change="handleChange">-->
<!--            <a-select-option v-for="(item,index) in InspectionPoint" :key="index" :value="item.machine_room_id.toString()">{{item.machine_room_name}}</a-select-option>-->
<!--          </a-select>-->
          <div class="role-modal-container">
            <a-transfer :disabled="templateType==='detail'" style="width: 460px" :titles="['待选择', '已选中']" :render="item => item.title" :target-keys="checkedKeys" :data-source="inspectionOptions" @change="inspectionCheckedChange">
            </a-transfer>
          </div>
        </a-form-model-item>
        <a-button style="margin-top: 5px;margin-left: 50px" v-if="templateType==='add'||templateType==='edit'" type="primary" @click="dragVisible=true">排序</a-button>
<!--        <sapn style="color:red;">提示：请在选择班次时注意，如果为两班制须选择白班和夜班，如果为三班制则选择早中晚班</sapn>-->
      </a-form-model>
    </a-modal>
    <a-modal v-model="dragVisible" width="300px" >
      <template slot="footer">
        <a-button @click="dragVisible=false">确认</a-button>
      </template>
      <div><span style="color: red">提示：</span></div>
      <div><span style="color: red;">请用鼠标左键按住巡查点调整顺序</span></div>
      <draggable style="margin-top: 10px" v-model="checkedKeys" :options="{group:'people'}" @start="handleDragStart" @end="handleDragEnd">
        <div v-for="(item, index) in checkedKeys" :key="item">{{ getItemName(item) }}</div>
      </draggable>
    </a-modal>
    <a-modal v-model="lineVisible" width="1000px" height="600px">
      <template slot="footer">
        <a-button @click="lineVisible=false">关闭</a-button>
      </template>
      <patrol-line :key="componentKey" :lineData="detailData"></patrol-line>
    </a-modal>
  </div>
</template>
<script>
import pagination from "@/mixins/pagination";
import moment from "moment";
import {
  addEngineerPatrolDailyTemplate,
  getEngineerPatrolDailyTemplateListByCondition,
  modifyEngineerPatrolDailyTemplate
} from "A/patrol";
import {mapState} from "vuex";
import {getMonitorPointNameList} from "A/monitoring";
import {getItemFromArrayByKey} from "U/index";
import patrolLine from "V/material/engineerPatrolScheme/patrolLine.vue";
import draggable from 'vuedraggable'
import {getMachineRoomListByCondition} from "A/facilityandequipment";
export default {
  name: "Index",
  components: {patrolLine,draggable},
  mixins: [pagination],
  data() {
    return {
      moment,
      title:'生成人工巡查线路',
      tableLoading: false,
      tableColumns: [
        {
          title: '巡查线路名称',
          dataIndex: 'patroldailytemplatename',
          key: 'patroldailytemplatename',
          ellipsis: true,
          scopedSlots: { customRender: 'patroldailytemplatename' },
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '巡查最早开始时刻',
          dataIndex: 'starttime',
          key: 'starttime',
          ellipsis: true,
          scopedSlots: { customRender: 'starttime' },
        },
        {
          title: '巡查最晚结束时刻',
          dataIndex: 'endtime',
          key: 'endtime',
          ellipsis: true,
          scopedSlots: { customRender: 'endtime' },
        },
        // {
        //   title: '巡查时间间隔',
        //   dataIndex: 'intervals',
        //   key: 'intervals',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'intervals' },
        // },
        // {
        //   title: '巡查线路',
        //   dataIndex: 'patrolline',
        //   key: 'patrolline',
        //   ellipsis: true,
        // },
        {
          title: '设置人',
          dataIndex: 'recordman',
          key: 'recordman',
          ellipsis: true,
          width: 100,
        },
        {
          title: '设置时间',
          dataIndex: 'createtime',
          key: 'createtime',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'createtime' },
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData:[],
      queryParams: {
        patroldailytemplateid:'',
        monitorpointname:'',
        monitorpointnum:'',
        patroldailytemplatename:'',
        templatetype:0,
        starttime:'',
        endtime:'',
        patrolline:null,
        intervals:'',
        recordman:'',
      },
      formRules: {
        patroldailytemplatename: [{required: true, message: '请输入巡查模板名称'}],
        // patrolline: [{required: true, message: '请选择巡查线路'}],
        starttime: [{required: true, message: '请选择开始巡查时刻'}],
        endtime: [{required: true, message: '请选择结束巡查时刻'}],
      },
      templateVisible:false,
      templateType:'detail',
      patrolline:[],
      InspectionPoint:[],
      checkedKeys: [],
      inspectionOptions: [],
      lineVisible:false,
      dragVisible:false,
      detailData:{},
      componentKey:0,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  created() {
    this.getTableData(true)
    this.getMachineRoomList()
    this.getMonitorpointName()
  },
  methods:{
    getItemName(key) {
      // 根据key从mockData中获取对应的项名称
      const item = this.inspectionOptions.find((item) => item.key === key);
      return item ? item.title : "";
    },
    handleDragStart(event){
      this.draggingItem=this.checkedKeys[event.oldIndex]
    },
    handleDragEnd(event) {
      const draggingItemKey = this.draggingItem; // 获取存储的拖拽item的key
      const newIndex = event.newIndex;
      const targetKeys = [...this.checkedKeys]; // 复制目标列表keys，避免直接修改影响组件状态和渲染
      const sourceIndex = targetKeys.indexOf(draggingItemKey); // 获取拖拽item在目标列表中的位置索引
      const newPosition = newIndex // 计算拖拽后的新位置
      // 将拖拽item从原位置移除，并插入到新位置，更新目标列表keys并触发组件更新（通过调用inspectionCheckedChange）
      targetKeys.splice(sourceIndex, 1); // 从目标列表中移除拖拽item的key（从数组中删除）
      targetKeys.splice(newPosition, 0, draggingItemKey); // 在目标列表中插入拖拽item的key（添加到指定位置）
      this.checkedKeys = targetKeys; // 更新目标列表keys的value并存储到组件的属性中（响应式）
      this.inspectionCheckedChange(this.checkedKeys); // 触发组件的change事件，将更新后的目标列表keys传递给穿梭框组件（响应式）
    },
    getMonitorpointName(){
      getMonitorPointNameList({userdepid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.queryParams.monitorpointnum=res.item.length>0?res.item[0].monitorpointnum:""
        }
      })
    },
    getMachineRoomList(){
      getMachineRoomListByCondition({status:0}).then(res=>{
        if(res&&res.returncode==='0'){
          this.InspectionPoint=res.item
          this.inspectionOptions = res.item.map(item => {
            return {
              key: item.machine_room_id+'',
              title: item.machine_room_name
            }
          })
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        status:0,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEngineerPatrolDailyTemplateListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    initDetail(record){
      this.$nextTick(() => {
        for(let key in this.queryParams) {
          if(key==="starttime"||key==="endtime"){
            this.queryParams[key] = moment("1970-01-01 "+record[key],"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")||'';
          }else if(key==="patrolline"){
            this.patrolline = record[key].toString().split(',')||'';
            this.checkedKeys = record[key].toString().split(',')||'';
          }else{
            this.queryParams[key] = record[key]||'';
          }
        }
      })
    },
    showModal(type){
      if(type==='add'){
        if(this.$refs.advancedForm){
          this.$refs.advancedForm.resetFields();
        }
        this.inspectionOptions=[]
        this.checkedKeys = []
        this.getMachineRoomList()
        this.templateVisible=true
      }else if(type==='detail'){
        this.templateVisible=true
        this.title="人工巡查线路详情"
      }else if(type==='edit'){
        this.templateVisible=true
        this.title="修改人工巡查线路"
      }
      this.templateType=type
    },
    handleChange(value){
      this.queryParams.patrolline=""
      if(value){
        for(let i = 0;i<value.length;i++){
          this.queryParams.patrolline=this.queryParams.patrolline+value[i]+","
        }
        this.queryParams.patrolline=this.queryParams.patrolline.toString().substring(0,this.queryParams.patrolline.toString().length-1)
      }
    },
    handleOk(){
      this.queryParams.recordman=this.userInfo.username
      this.queryParams.starttime = this.queryParams.starttime?moment(this.queryParams.starttime).format("HHmmss").toString().substring(0,4)+"00":null
      this.queryParams.endtime = this.queryParams.endtime?moment(this.queryParams.endtime).format("HHmmss").toString().substring(0,4)+"00":null
      let params = {
        ...this.queryParams
      }
      this.$refs.advancedForm.validate(valid => {
        if (valid) {
          if (this.templateType === 'add') {
            addEngineerPatrolDailyTemplate(params).then(res => {
              if (res && res.returncode == '0') {
                this.templateVisible = false
                this.$message.success("操作成功")
                this.getTableData()
                this.tabskey = '3'
              } else {
                this.queryParams.starttime = null
                this.queryParams.endtime = null
                this.$message.error(res.errormsg)
              }
            })
          } else if (this.templateType === 'edit') {
            modifyEngineerPatrolDailyTemplate(params).then(res => {
              if (res && res.returncode == '0') {
                this.templateVisible = false
                this.$message.success("操作成功")
                this.getTableData()
                this.tabskey = '3'
              } else {
                this.queryParams.starttime = null
                this.queryParams.endtime = null
                this.$message.error(res.errormsg)
              }
            })
          }
        } else {
          this.$message.warning('请确认信息填写完整')
          return false;
        }
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let patroldailytemplateid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'patroldailytemplateid', patroldailytemplateid);
      if(type == 'delete') {
        this.deleteConfirm(patroldailytemplateid, record)
      }else if(type == 'detail'){
        this.showModal(type)
        this.initDetail(record)
      }else if(type == 'edit'){
        this.showModal(type)
        this.initDetail(record)
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要作废该线路吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.patroldailytemplateid);
      }).catch(()=>{
      });
    },
    delete(patroldailytemplateid) {
      this.showLoading();
      if(patroldailytemplateid) {
        let params = {
          patroldailytemplateid,
          status:1
        };
        modifyEngineerPatrolDailyTemplate(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData(true);
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    rowClick(record) {
      // this.showModal("detail")
      this.detailData=record
      this.componentKey=this.componentKey+1
      this.lineVisible=true
      // this.initDetail(record)
    },
    inspectionCheckedChange(targetKeys) {
      this.checkedKeys = targetKeys;
      this.queryParams.patrolline=""
      if(this.checkedKeys){
        for(let i = 0;i<this.checkedKeys.length;i++){
          this.queryParams.patrolline=this.queryParams.patrolline+this.checkedKeys[i]+","
        }
        this.queryParams.patrolline=this.queryParams.patrolline.toString().substring(0,this.queryParams.patrolline.toString().length-1)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>